h1 {
  @extend .mb-1;
  @extend .text-uppercase;
}


.paper {
  @extend .bg-white;
  @extend .p-3;
  @extend .rounded-lg;
  @extend .shadow-sm;
}


.btn-primary,
.btn-secondary,
.btn-danger,
.btn-outline-primary,
.btn-outline-danger {
  @extend .rounded-pill;
  @extend .text-uppercase;
  font-weight: $font-weight-bold;
}

.btn-primary {
  background: linear-gradient(180deg, #ff503c 0%, #e44937 100%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
}

.btn-secondary {
  &:hover {
    border-color: $dark;
  }

  &:hover,
  &:focus {
    background-color: $white;
  }
}

.btn-outline-primary,
.btn-outline-info {
  background-color: $white;
}

.btn-outline-danger {
  border-color: transparent;
  color: inherit;

  .icon {
    color: $danger;
  }

  &:hover {
    .icon {
      color: inherit;
    }
  }
}


.btn .icon {
  margin-right: .5 * $spacer;
}


.card-title {
  @extend .bg-info;
  @extend .mx-3;
  @extend .px-3;
  @extend .py-1;
  @extend .text-uppercase;
  @extend .text-white;
  border-radius: $border-radius-lg $border-radius-lg 0 0;
  max-width: fit-content;
}

.card {
  @extend .shadow-sm;
}


.icon-button {
  @extend .border-0;
  @extend .line-height-normal;
  @extend .p-1;
  background: none;

  &:focus {
    outline: none;
  }
}