@import 'bootstrap-variables';

@import 'bootstrap';
@import 'bootstrap-helpers';

@import 'google-fonts';

@import 'partials/forms';
@import 'partials/general';
@import 'partials/login';
@import 'partials/sidebar-layout';
@import 'partials/specialized-components';
@import 'partials/ui-blocks';


:root {
  --bs-breakpoint-lg: #{map-get($grid-breakpoints, 'lg')};

  // JS assigns here the Browser's Scrollbar Width
  --scrollbar-width: 0;
}