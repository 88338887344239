.form-control,
.form-select {

  &.border-success {
    box-shadow: 0 0 0 ($input-btn-focus-width / 3) rgba($success, $input-btn-focus-color-opacity * 2);

    &:focus {
      box-shadow: 0 0 0 $input-btn-focus-width rgba($success, $input-btn-focus-color-opacity);
    }
  }
}