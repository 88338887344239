.login-page-bg {
  background-color: #f8f6c2;

  .text-wrap {
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }

  h1 {
    text-transform: unset !important;
  }
}

.login-page-bg .content-wrap .row {
  border-radius: 25px;
}