$dashboard-sidebar-width: 250px;
$dashboard-sidebar-main-content-max-width: 1130px;


.dashboard-wrapper {
  main {
    min-height: 100vh;
  }
}

.dashboard-sidebar-main-content {
  max-width: $dashboard-sidebar-main-content-max-width;
}

.dashboard-sidebar {
  background-color: $white;
  display: none;
  bottom: 0;
  left: 0;
  position: fixed;
  top: 0;
  width: $dashboard-sidebar-width;
  z-index: $zindex-modal;

  &.sidebar-visible {
    display: block;
  }
}


.sidebar-link {
  color: $body-color;
  display: block;
  padding: (.5 * $spacer) 0;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    color: $white;
    text-decoration: none;

    .sidebar-link-label {
      background-color: rgba($dark, .8);
    }
  }

  +a {
    border-top: 2px solid $light;
  }
}

.sidebar-link-label {
  border-radius: 0 50rem 50rem 0;
  display: block;
  margin-right: -(1.5 * $spacer);
  padding: ($spacer) (1.5 * $spacer);
}

.sidebar-link-active,
.sidebar-link-active:hover {
  color: $white;

  .sidebar-link-label {
    background-color: rgba($info, .8);
  }
}


.topbar-wrapper {
  left: 0;
  right: 0;
  z-index: $zindex-fixed;

  .body-with-backdrop & {
    padding-right: var(--scrollbar-width);
  }
}

.topbar-content {
  max-width: $dashboard-sidebar-main-content-max-width;
  width: 100%;
}


@include media-breakpoint-up(lg) {

  .dashboard-wrapper {
    padding-left: $dashboard-sidebar-width;

    main {
      border-radius: 3rem 0 0;
    }
  }

  .dashboard-sidebar {
    display: block;
    position: fixed;
  }

  .topbar-wrapper {
    left: $dashboard-sidebar-width;
  }

}