/* stylelint-disable selector-class-pattern */
.public-DraftEditor-content>div {
  min-height: 10rem;
  padding: .5 * $spacer;
}


.html-editor-large-content {
  .public-DraftEditor-content>div {
    max-height: 80vh;
    min-height: 20rem;
    overflow: auto;
  }
}


.public-DraftEditor-content {
  .paragraph {
    margin-bottom: $spacer;
  }

  figure {
    display: block;
    margin-bottom: $spacer;
    text-align: center;
  }

  img {
    max-width: 100%;
  }
}