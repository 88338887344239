body {
  min-width: 360px;
}

.body-with-backdrop {
  overflow: hidden;
  padding-right: var(--scrollbar-width);
}


.line-height-normal {
  line-height: normal;
}


.faded {
  opacity: .5;
}


.btn-danger-on-hover {
  &:hover {
    @include gradient-bg(darken($danger, 7.5%));
    color: color-contrast($danger);
    border-color: darken($danger, 10%);
  }
}

.danger-on-hover {
  &:hover {
    color: $danger;
    opacity: 1;
  }
}


.input-with-primary-button {
  background: transparent;
  margin-right: -$spacer;
  padding-right: $input-padding-x + $spacer;

  +.btn-primary {
    z-index: $zindex-dropdown + 1;
  }
}