@import 'specialized-components/avatar-menu';
@import 'specialized-components/html-editor';


$inline-edit-shadow: 0 0 0 (.5 * $input-btn-focus-width) rgba($dark, $input-btn-focus-color-opacity);


.inline-edit-input {
  border: 0;
  border-radius: $input-border-radius-sm;
  box-shadow: $input-btn-focus-box-shadow;
  outline: 0;
  padding: 0;
  width: 100%;
}

.inline-edit-text {
  border-radius: $input-border-radius-sm;
  cursor: pointer;
  display: inline-block;
  width: 100%;

  &:hover {
    box-shadow: $inline-edit-shadow;
  }
}

.inline-edit-placeholder {
  box-shadow: $inline-edit-shadow;
}

.highlight-actions-on-hover:hover {
  .inline-edit-text {
    box-shadow: $inline-edit-shadow;
  }
}


.row-actions {
  padding: 0;
  text-align: right;
  vertical-align: middle;
}

.row-actions-wrapper {
  @extend .rounded-pill;
  display: inline-block;
  padding: (.5 * $table-cell-padding-y) (1 * $table-cell-padding-x);

  >button,
  >a {
    margin-left: (.5 * $table-cell-padding-x);
    margin-right: (.5 * $table-cell-padding-x);
  }
}

tr:hover {
  .row-actions-wrapper {
    background-color: rgba($light, .7);
  }

  .faded {
    opacity: .75;

    &:hover {
      opacity: 1;
    }
  }
}


.underline-on-hover {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}