.avatar-menu {
  max-width: fit-content;
}


.avatar-menu-snippet {
  cursor: pointer;
  line-height: 1rem;
  transition: $btn-transition;

  .show &,
  &:hover {
    @extend .shadow-sm;
    background-color: $white;
  }

  img {
    max-height: 40px;
    max-width: 40px;
  }
}