// Color system
$primary: #e94232;
$secondary: white;
$danger: #c20a1d;
$info: #184f66;
$light: #eaeaea;


// Spacing
$spacer: 1rem;


// Body
$body-color: #4e4e4e;


// Components
$border-radius-lg: .8rem;

$component-active-bg: $info;


// Typography
$font-family-sans-serif: "'Lato', sans-serif";
$font-weight-bold: 700;
$font-weight-bolder: 700;

$headings-font-weight: $font-weight-bold;


// Buttons
$btn-padding-y: .5 * $spacer;
$btn-padding-x: 2 * $spacer;
$btn-line-height: 1;

$btn-border-width: .15rem;


// Forms
$form-label-margin-bottom: .25rem;


// Cards
$card-title-spacer-y: 0;
$card-border-width: 0;
$card-border-radius: $border-radius-lg;


// Badges
$badge-font-size: .8em;
$badge-padding-y: .3em;
$badge-padding-x: 1em;


// Modal
$modal-inner-padding: .5 * $spacer;
$modal-footer-margin-between: $spacer;

$modal-content-border-width: 0;